import React, { createContext, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { auth } from '../config/firebase';
import { signInAnonymouslyUser, signOut } from '../services/authService';
import { onAuthStateChanged } from 'firebase/auth';
import { useSignin } from '../hooks/useSignin';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const isAnonRef = useRef(false);

    const { mutate: signin } = useSignin();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                console.log("Auth State Changed: null")
                setCurrentUser(null);
                // Prevent re-signin attempt for anonymous user;
                // signout may happen due to backend error or on Google Login
                if (isAnonRef.current) {
                    return;
                }
                // Automatically sign in anonymously
                const anonymousUser = await signInAnonymouslyUser();
                if (!anonymousUser) {
                    alert("Could not Sign-In Anonymously. Please try Google Sign-In instead.");
                } else {
                    isAnonRef.current = true;
                }
            } else {
                console.log("Auth State Changed: user")
                signin(null, {
                    onSuccess: () => {
                        console.log('Backend sign-in successful');
                        setCurrentUser(user);
                        if (user.isAnonymous) {
                            toast.info('You are currently using an anonymous account. Sign in with Google to save your progress.');
                        } else {
                            isAnonRef.current = false;
                        }
                    },
                    onError: async (error) => {
                        console.error("Backend sign-in failed:", error);
                        if (
                            user.isAnonymous &&
                            error.response?.status === 403
                        ) {
                            toast.error("Please sign in with Google to continue using the product.");
                        } else {
                            alert("Could not complete sign-in due to a server error. Please try again.");
                        }
                        await signOut();
                    },
                });
            }
        });
        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    );
};
