import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const ChatMessage = ({ text, isUser, onReferenceClick }) => {
    // Initialize mainContent and references
    let mainContent = text;
    let references = [];

    // If the message is from the assistant, parse references
    if (!isUser) {
        const refSectionIndex = text.indexOf('### References:');
        if (refSectionIndex !== -1) {
            // Extract main content without references
            mainContent = text.substring(0, refSectionIndex).trim();
            const refText = text.substring(refSectionIndex + '### References:'.length).trim();
            references = parseReferencesFromContent(refText);
        }
    }

    // Replace new lines with Markdown-compatible line breaks
    const formattedText = mainContent.replace(/\n/g, '  \n');

    return (
        <div className={`p-2 ${isUser ? 'text-right' : 'text-left'}`}>
            <div
                className={`inline-block p-3 rounded-lg text-left ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-300'
                    }`}
            >
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        h1: ({ children }) => (
                            <h1 className="text-2xl font-bold mt-4 mb-2">{children}</h1>
                        ),
                        h2: ({ children }) => (
                            <h2 className="text-xl font-semibold mt-3 mb-2">{children}</h2>
                        ),
                        h3: ({ children }) => (
                            <h3 className="text-lg font-semibold mt-3 mb-2">{children}</h3>
                        ),
                        h4: ({ children }) => (
                            <h4 className="text-md font-medium mt-2 mb-1">{children}</h4>
                        ),
                        h5: ({ children }) => (
                            <h5 className="text-sm font-medium mt-2 mb-1">{children}</h5>
                        ),
                        h6: ({ children }) => (
                            <h6 className="text-xs font-medium mt-1 mb-1">{children}</h6>
                        ),
                        p: ({ children }) => <p className="mb-2">{children}</p>,
                        li: ({ children }) => <li className="ml-4 list-decimal">{children}</li>,
                    }}
                >
                    {formattedText}
                </ReactMarkdown>
                {/* References */}
                {!isUser && references.length > 0 && (
                    <div className="mt-2">
                        <h3 className="font-bold">References:</h3>
                        {references.map((ref, index) => (
                            <div key={index}>
                                <button
                                    onClick={() =>
                                        onReferenceClick(
                                            ref.documentName,
                                            ref.articleNumber,
                                            ref.articleTitle
                                        )
                                    }
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        padding: 0,
                                    }}
                                >
                                    {`${ref.documentName}, Article ${ref.articleNumber}, ${ref.articleTitle}`}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

function parseReferencesFromContent(refText) {
    const references = [];

    // Ensure refText is a non-empty string
    if (typeof refText !== 'string' || refText.trim() === '') {
        console.warn('Invalid refText input: ', refText);
        return references;
    }

    const refLines = refText.split('\n').filter((line) => line.trim() !== '');
    refLines.forEach((line) => {
        // Ensure each line is properly formatted
        const parts = line.split('|').map((s) => s.trim());
        if (parts.length < 3) {
            console.warn('Malformed reference line: ', line);
            return;
        }

        const [documentName, articleNumberPart, articleTitle] = parts;

        // Validate and clean up extracted fields
        if (!documentName || !articleNumberPart) {
            console.warn('Missing documentName or articleNumber in line: ', line);
            return;
        }

        const articleNumberMatch = articleNumberPart.match(/Article (\d+)/);
        if (!articleNumberMatch) {
            console.warn('Malformed articleNumberPart: ', articleNumberPart);
            return;
        }

        const articleNumber = articleNumberMatch[1];

        references.push({
            documentName,
            articleNumber,
            articleTitle: articleTitle || 'Untitled Article', // Provide default if missing
        });
    });

    return references;
}

export default ChatMessage;
