import { useQuery } from 'react-query';
import { getUser } from '../api/lawChatApi';

export const useUser = (currentUser) => {
    return useQuery(
        ['user', currentUser?.uid],
        getUser,
        {
            enabled: !!currentUser,
            staleTime: 0,
        });
};
