import React from 'react';

const NoCreditsModal = ({ onClose, onGetMoreCredits }) => {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-96 rounded-lg shadow-lg p-6 relative">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl focus:outline-none"
                    onClick={onClose}
                >
                    &times;
                </button>

                <h2 className="text-xl font-bold mb-4">Out of Credits</h2>
                <p className="mb-4">
                    It looks like you've run out of credits. You need more credits to continue asking questions.
                </p>
                <button
                    onClick={() => {
                        onClose(); // Close this modal
                        onGetMoreCredits(); // Open ProfileModal with Get More Credits expanded
                    }}
                    className="w-full bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                >
                    Get More Credits
                </button>
            </div>
        </div>
    );
};

export default NoCreditsModal;
