// src/components/DisclaimerModal.js
import React from 'react';

const DisclaimerModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
                <h2 className="text-xl font-bold mb-4">Welcome to Lexcha</h2>
                <p className="mb-4 text-gray-700">
                    Our service is designed to provide insights into Korean laws based on officially published resources. It is intended solely for informational and exploratory purposes and does not constitute legal advice. For specific legal concerns, please consult a qualified legal professional or law firm.
                </p>
                <p className="mb-4 text-gray-700">
                    본 서비스는 공인된 자료를 바탕으로 한국 법률에 대한 통찰을 제공하기 위해 설계되었습니다. 본 서비스는 정보 제공 및 탐색 목적으로만 제공되며, 법적 조언을 구성하지 않습니다. 특정 법적 문제에 대해서는 자격을 갖춘 법률 전문가나 로펌에 문의하시기 바랍니다.
                </p>
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    I Understand / 이해했습니다
                </button>
            </div>
        </div>
    );
};

export default DisclaimerModal;
