import React from 'react';

const PrivacyPolicy = () => {
    return (
        <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vQc-BKjuh07SxTfFThhVU5PBHVyw8XMxYYTftc5y0pnRl96VH7l88LZBlAoHVDVHRIJyXElRH2PuhlA/pub?embedded=true"
            style={{
                width: "100%",
                height: "100vh",
                border: "none"
            }}
            title="Privacy Policy"
        ></iframe>
    );
};

export default PrivacyPolicy;