import React, { useEffect, useState, useRef, useCallback } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import Fuse from 'fuse.js';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useMediaQuery } from 'react-responsive';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightPattern(text, pattern) {
  if (!pattern) return text;

  const escapedPattern = escapeRegExp(pattern);
  const regex = new RegExp(`(${escapedPattern})`, 'g'); // Case-sensitive. Use 'gi' for case-insensitive.
  return text.replace(regex, (match) => `<mark>${match}</mark>`);
}

function ToggleButton({ isMobile, isPdfViewerVisible, togglePdfViewer }) {
  // Determine which icon to display based on viewport and visibility
  const renderIcon = () => {
    if (isMobile) {
      return <FaTimes size={20} />;
    }
    return isPdfViewerVisible ? <FaChevronRight /> : <FaChevronLeft />;
  };

  // Determine the button's ARIA label
  const getAriaLabel = () => {
    if (isMobile) {
      return 'Close PDF Viewer';
    }
    return isPdfViewerVisible ? 'Hide PDF Viewer' : 'Show PDF Viewer';
  };

  // Determine the button's className based on viewport
  const getButtonClass = () => {
    if (isMobile) {
      return 'bg-gray-800 text-white p-3 rounded-full shadow-lg fixed bottom-4 right-4 z-50';
    }
    return 'p-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400';
  };

  return (
    <button
      onClick={togglePdfViewer}
      aria-label={getAriaLabel()}
      className={getButtonClass()}
      style={isMobile ? {} : {}}
    >
      {renderIcon()}
    </button>
  );
}

function PdfViewer({ pdfUrl, searchTerm, togglePdfViewer, pdfDocumentName, isPdfViewerVisible }) {
  const [numPages, setNumPages] = useState(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [textItems, setTextItems] = useState([]);
  const [isTextContentLoaded, setIsTextContentLoaded] = useState(false);
  const matchedItemRef = useRef(null);
  const containerRef = useRef(null);
  const pdfDocumentVersion = useRef(0); // Unique identifier for each PDF document

  const [containerWidth, setContainerWidth] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Update container width on resize
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth(); // Initial width
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  // Handle successful document load
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
    setPdfDocument(pdf);
  }

  // Update pdfDocumentVersion when pdfUrl changes
  useEffect(() => {
    pdfDocumentVersion.current += 1;
  }, [pdfUrl]);

  // Extract individual text items from all pages
  useEffect(() => {
    setTextItems([]);
    setIsTextContentLoaded(false);

    const currentVersion = pdfDocumentVersion.current; // Capture the current version

    const loadTextContent = async () => {
      if (pdfDocument) {
        const allTextItems = [];
        for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
          const page = await pdfDocument.getPage(pageNumber);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            allTextItems.push({ pageNumber, textItem: item.str });
          });
        }
        // Only update state if the PDF hasn't changed during loading
        if (pdfDocumentVersion.current === currentVersion) {
          setTextItems(allTextItems);
          setIsTextContentLoaded(true);
        }
      }
    };
    loadTextContent();
  }, [pdfDocument]);

  // Perform fuzzy search and store only the first matched item with match indices
  useEffect(() => {
    if (searchTerm && isTextContentLoaded) {
      const options = {
        keys: ['textItem'],
        threshold: 0.3, // Adjust for sensitivity
        ignoreLocation: true,
        minMatchCharLength: 2,
        includeMatches: true, // Enable match data
      };
      const fuse = new Fuse(textItems, options);
      const results = fuse.search(searchTerm);

      if (results.length > 0) {
        const firstResult = results[0];
        const firstMatch = firstResult.item;
        const firstMatchPage = firstMatch.pageNumber;

        const { pageNumber, textItem } = firstResult.item;
        const match = firstResult.matches[0]; // Get the first match within the textItem
        const { indices } = match; // Array of [start, end] indices

        // Store only the first match's indices
        matchedItemRef.current = {
          pageNumber,
          text: textItem,
          indices,
        };

        // Scroll to the first matched page
        const pageElement = document.querySelector(`.page-container-${firstMatchPage}`);
        if (pageElement) {
          pageElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Only alert if the version hasn't changed
        if (pdfDocumentVersion.current === currentVersionRef.current) {
          alert('Article not found.');
        }
        matchedItemRef.current = null;
      }
    } else {
      matchedItemRef.current = null;
    }
  }, [searchTerm, isTextContentLoaded, textItems]);

  const currentVersionRef = useRef(pdfDocumentVersion.current);

  useEffect(() => {
    currentVersionRef.current = pdfDocumentVersion.current;
  }, [pdfDocumentVersion.current]);

  // Custom text renderer to highlight only the first matched text item
  const customTextRenderer = useCallback(
    (textItem) => {
      // Attempt RegEx Match Highlighting
      const highlightedRegEx = highlightPattern(textItem.str, searchTerm);
      if (highlightedRegEx !== textItem.str) {
        return highlightedRegEx;
      }

      // Fallback to Fuzzy Match Highlighting
      if (!matchedItemRef.current) return textItem.str;

      const { pageNumber, text, indices } = matchedItemRef.current;

      // Check if the current textItem matches the stored matchedItem
      if (pageNumber !== textItem.pageNumber || text !== textItem.str) {
        return textItem.str;
      }

      if (!indices || indices.length === 0) {
        return textItem.str;
      }

      // Highlight only the first match
      const [start, end] = indices[0];
      const beforeMatch = textItem.str.slice(0, start);
      const matchedText = textItem.str.slice(start, end + 1); // end is inclusive
      const afterMatch = textItem.str.slice(end + 1);

      return `${beforeMatch}<mark>${matchedText}</mark>${afterMatch}`;
    },
    [searchTerm]
  );

  // Determine the width based on screen size and visibility
  const getWidth = () => {
    if (isMobile) {
      return isPdfViewerVisible ? '100vw' : '0';
    }
    return isPdfViewerVisible ? '50%' : '50px';
  };

  // Determine the minWidth based on screen size
  const getMinWidth = () => {
    if (isMobile) {
      return '0';
    }
    return '50px';
  };

  // Determine whether to display the PDF content
  const shouldDisplayPdfContent = () => {
    if (isMobile) {
      return isPdfViewerVisible;
    }
    return true; // On desktop, always render the content container
  };

  return (
    <div
      className="bg-gray-100 flex flex-col transition-all duration-300 overflow-hidden"
      style={{
        width: getWidth(),
        minWidth: getMinWidth(),
      }}
    >
      {/* Header with Document Name and Toggle Button */}
      <div className="flex items-center justify-between p-2 bg-gray-200 flex-shrink-0">
        {isPdfViewerVisible && (
          <h2 className="text-lg font-semibold truncate">{pdfDocumentName}</h2>
        )}
        <ToggleButton
          isMobile={isMobile}
          isPdfViewerVisible={isPdfViewerVisible}
          togglePdfViewer={togglePdfViewer}
        />
      </div>

      {/* PDF Content */}
      {shouldDisplayPdfContent() && (
        <div
          className={`flex-1 overflow-y-auto transition-opacity duration-300 ${isPdfViewerVisible ? 'opacity-100' : 'opacity-0'
            }`}
        >
          <div
            ref={containerRef}
            style={{ overflowY: 'scroll', height: '100%', width: '100%', position: 'relative' }}
          >
            <Document key={pdfUrl} file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from({ length: numPages }, (_, index) => (
                <div key={`page_${index + 1}`} className={`page-container-${index + 1}`}>
                  <Page
                    pageNumber={index + 1}
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    customTextRenderer={customTextRenderer}
                    width={containerWidth} // Set page width to container width
                    loading={null}
                  />
                </div>
              ))}
            </Document>
          </div>
        </div>
      )}
    </div>
  );
}

export default PdfViewer;