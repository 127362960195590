import React from 'react';

const TermsOfUse = () => {
    return (
        <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vReeyqWeqDNscFbp1g_xLSJIPYaQeCeIaawdlaqAA_p60kumOarxLICeNd98R0WBEa6G3you2XuJI8S/pub?embedded=true"
            style={{
                width: "100%",
                height: "100vh",
                border: "none"
            }}
            title="Terms of Service"
        ></iframe>
    );
};

export default TermsOfUse;
